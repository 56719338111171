/**
 * Created by osirvent on 08/04/2016.
 */
angular
    .module('annexaApp')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('annexa.thirds', {
                url: '/thirds',
                templateUrl: './views/layout/thirds.html',
                controller: 'ThirdsController',
                data: {
                    title: 'global.thirds.toptitle',
                    displayName: 'global.thirds.toptitle',
                    authenticate: true,
                    permissions: {
                        only: 'view_thirds'
                    }
                },
                resolve: {
                    getTerritorytLists: ['TerritoryFactory', function (TerritoryFactory) {
                        return TerritoryFactory.initializeLists();
                    }]
                }
            })
            .state('annexa.thirds.new', {
                url: '/new',
                templateUrl: './views/layout/newThird.html',
                controller: 'ThirdsNewController',
                params: {
                    third: undefined
                },
                data: {
                    title: 'global.thirds.toptitle',
                    displayName: 'global.thirds.literals.newthird',
                    authenticate: true,
                    permissions: {
                        only: 'new_third'
                    }
                }
            })
            .state('annexa.thirds.edit', {
                url: '/edit/:id',
                templateUrl: './views/layout/editThird.html',
                controller: 'ThirdsEditController',
                data: {
                    title: 'global.thirds.toptitle',
                    displayName: 'global.thirds.titleEditThird',
                    authenticate: true,
                    permissions: {
                        only: 'view_thirds'
                    }
                },
                resolve: {
                    third: ['$q', '$state', '$stateParams', 'ThirdFactory', '$rootScope', '$timeout', function($q, $state, $stateParams, ThirdFactory, $rootScope, $timeout) {
                        if($stateParams.id) {
                            $rootScope.showLoadingdivSignin = true;
                            return ThirdFactory.getThird($stateParams.id);
                        } else {
                            $timeout(function() {
                                $state.go('annexa.thirds')
                            });
                            return $q.reject('No third');
                        }
                    }],
                    getDocumentsData: ['DccumentsFactory', function (DccumentsFactory) {
                        return DccumentsFactory.getDocumentLists();
                    }],
                    getTaskLists:['GlobalDataFactory', function (GlobalDataFactory) {
                        return GlobalDataFactory.initializeTaskLists();
                    }]
                }
            });
    }]);